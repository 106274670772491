<template>
  <b-row class="match-height">
    <b-col lg="8">
      <ConfirmJobSelection :profile-dic="jobSelectionDic" />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import getSingleData from '@/firestore/data/get'
import ConfirmJobSelection from '@/components/company/jobSelection/ConfirmJobSelection.vue'

export default {
  components: {
    BRow,
    BCol,
    ConfirmJobSelection,
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters('jobSelection', ['jobSelectionDic']),
  },
  async mounted() {
    const data = {
      collection: 'jobSelection',
    }
    const response = await getSingleData(data)
    if (response.status === 'success') {
      this.$store.dispatch('jobSelection/updateDic', response.data)
    } else {
      window.console.log('error', response.msg)
    }
  },
}
</script>

<style lang="scss">
</style>
